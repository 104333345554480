import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Row, Col } from "react-bootstrap";

import "../../styles/main.scss";
import Layout from "../../layouts/default";
import PageIcon from "../../components/PageIcon";

export const Head = () => (
  <>
    <title>MandarInput Blog: Comprehensible Input for Mandarin Chinese</title>
    <PageIcon />
  </>
);

const BlogIndex = ({ data }: PageProps<Queries.BlogIndexQuery>) => {
  const posts = data.allMarkdownRemark.nodes.slice().sort((a, b) => {
    if (
      a === null ||
      b === null ||
      a.frontmatter === null ||
      b.frontmatter === null ||
      a.frontmatter.date === null ||
      b.frontmatter.date === null
    ) {
      return 0;
    }

    return -a.frontmatter.date.localeCompare(b.frontmatter.date);
  });

  return (
    <Layout>
      <Row>
        <Col sm={12}>
          <h1>Blog</h1>
        </Col>
      </Row>

      <Row>
        <Col sm={12} lg={8}>
          {posts.map(
            (post) =>
              post !== null &&
              post.fields !== null && (
                <div className="card" key={post.fields.slug}>
                  <Link to={`/blog${post.fields.slug}`}>
                    <h2>{post.frontmatter?.title ?? "Blog post"}</h2>
                  </Link>
                  <section
                    dangerouslySetInnerHTML={{ __html: post.excerpt ?? "" }}
                  />
                  <p>
                    <Link to={`/blog${post.fields.slug}`}>Read more</Link>
                  </p>
                </div>
              )
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndex {
    allMarkdownRemark {
      nodes {
        frontmatter {
          title
        }
        excerpt(format: HTML)
        fields {
          slug
        }
        frontmatter {
          date
        }
      }
    }
  }
`;
